import React, { useState } from "react";
import { PolarisProvider } from "../../../components";
import {
    TrainingCheck,
    TRANSACTION_FILTERS,
    TransactionFilter,
    TxnSearch
} from "../../../layout/merchant";
import Scene from "../../../layout/merchant/settlements/Scene";
import { toDateString } from "../../../utils/dateFormat";

import { setItem } from "../../../core/auth/utils/storage";
import { datadownload } from "../../../layout/merchant/utils";

export default () => {
    const [selectedFilter, setSelectedFilter] = useState(TRANSACTION_FILTERS.ALL);
    const [selectedDates, setSelectedDates] = useState({
        start: null,
        end: null,
    });
    const [purchaseRef, setPurchaseRef] = useState();
    const [merchantRef, setMerchantRef] = useState();
    const [transactionRef, setTransactionRef] = useState();
    const [txnType, setTransactionType] = useState('Select Transaction Type');
    const [txnStatus, setTransactionStatus] = useState('Select Transaction Status');
    const [minValue, setMinimumValue] = useState("");
    const [maxValue, setMaximumValue] = useState("");
    const [searchTxn, setSearchTxn] = useState({})
    const [clearSearch, setClearSearch] = useState({})
    const handleSearch = () => {
        let pattern = /^[1-9]\d*\.?\d*$/;
        const minCheck = pattern.test(minValue)
        const maxCheck = pattern.test(maxValue)
        if ((minCheck === false && minValue?.length > 0) || (maxCheck === false && maxValue?.length > 0)) {
            return
        }
        if(purchaseRef===undefined&&merchantRef===undefined&&transactionRef===undefined&&minValue===''&&maxValue===''&&txnType==='Select Transaction Type'&&txnStatus==='Select Transaction Status'&&selectedDates.start===null && selectedDates.end===null){
            return;
        }
        let minimumValue= (parseFloat(minValue))*100;
        let maximumValue = (parseFloat(maxValue))*100;
        setSearchTxn(
            {
                fromDate: selectedDates.start != null ? toDateString(selectedDates.start) : undefined,
                toDate: selectedDates.end != null ? toDateString(selectedDates.end) : undefined,
                amountMin: minValue===(NaN || null || "")? undefined :parseInt(minimumValue),
                amountMax: maxValue===(NaN ||null|| "") ? undefined :parseInt(maximumValue),
                type: txnType===('Select Transaction Type' || undefined)? undefined: txnType,
                merchantReference: merchantRef,
                txnReference: transactionRef,
                purchaseReference: purchaseRef,
                status: txnStatus===('Select Transaction Status'|| undefined)? undefined: txnStatus
            }
        )
        setItem("page", 1);
    }
    const handleClear = () => {
        setSearchTxn({})
        setClearSearch({})
        window.location.reload()
    }
    const dataDownload = async () => {
       datadownload(selectedDates, minValue, maxValue, txnType, txnStatus, merchantRef, transactionRef, purchaseRef)
    }
    return (
        <PolarisProvider>
            <Scene statementsHeading='Transaction Dashboard' exportData={dataDownload} >
                <Scene.FullWidth>
                    <TransactionFilter
                        txnType={txnType}
                        txnStatus={txnStatus}
                        selectedFilter={selectedFilter}
                        fromDate={selectedDates?.start}
                        toDate={selectedDates?.end}
                        purchaseRef={purchaseRef}
                        transactionRef={transactionRef}
                        merchantRef={merchantRef}
                        minValue={minValue}
                        maxValue={maxValue}
                        setMinValue={(val) => {
                            setMinimumValue(val)
                        }}
                        setMaxValue={(val) => { setMaximumValue(val) }}
                        setTransactionType={(val) => { setTransactionType(val) }}
                        setTransactionStatus={(val) => { setTransactionStatus(val) }}
                        onFilterChange={(val) => setSelectedFilter(val)}
                        onSelectedDatesChange={(val) => setSelectedDates(val)}
                        purchaseChange={(val) => setPurchaseRef(val)}
                        merchantChange={(val) => setMerchantRef(val)}
                        transactionChange={(val) => setTransactionRef(val)}
                        handleSearch={() => handleSearch()}
                        handleClear={() => handleClear()}
                    />
                    <TrainingCheck>
                        <TxnSearch
                            selectedFilter={selectedFilter}
                            clearSearch={clearSearch}
                            handleSearch={searchTxn}
                        />
                    </TrainingCheck>
                </Scene.FullWidth>

            </Scene>
        </PolarisProvider>
    );
};
